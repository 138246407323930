.aboutPara{
    justify-content: center;
    font-size: 25px;
}

.about-wraper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.about-content {
    display: flex;
    align-items: center; /* Vertically align items */
}

.icon-list {
    margin-left: 20px; /* Add spacing between paragraph and icons */
}
