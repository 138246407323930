@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

:root{
  --primary-white: #fff;
  --ice-blue: #ABD1F3;
  --primary-dark: rgba(255,255,255,0)
}

body.dark-mode {
  background-color: #333;
  color: white;
}


/***************************************NAVBAR***************************************/
.smooth-scroll {
  scroll-behavior: smooth;
}


.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus{
  border-top: 0.1875rem solid var(--ice-blue);
}

.navbar-nav > li > a:hover{
  border-top: 0.1875rem solid var(--ice-blue);
}

nav a.nav-link{
  font-size: .8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  color: white;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  padding: 0;
  max-width: 17.25rem !important;
  height: 6rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-brand a {
  margin: 0;
  line-height: 1; /* Isso ajusta o alinhamento vertical do texto */
  font-size: 1.5rem; /* Ajuste o tamanho da fonte conforme necessário */
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.toggle-button {
  background: none; /* Remove o fundo */
  border: none; /* Remove a borda */
  outline: none; /* Remove o contorno que aparece ao focar o botão */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */

  /* Estilos adicionais para alinhamento e espaçamento, se necessário */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px; /* Espaçamento interno para dar espaço ao redor do ícone */
}

/* Estilos para os ícones dentro do botão */
.toggle-button svg {
  fill: currentColor; /* Faz o ícone herdar a cor do texto do botão */
  width: 24px; /* Largura do ícone */
  height: 24px; /* Altura do ícone */
}


.icon-sun, .icon-moon {
  display: none;
}

.dark-mode .icon-sun {
  display: block;
}

.light-mode .icon-moon {
  display: block;
}



/***************************************INTRO***************************************/

.intro-wraper{
  position: relative;

}

.hello{
  font-family: 'Roboto', sans-serif;
  font-size: 50px;
}

.introText{
  font-family: 'Roboto', sans-serif;
  font-size: 50px;
}

.introName{
  font-family: 'Roboto', sans-serif;
  color: var(--ice-blue) !important;
  font-size: 50px;
}

.introPara{
  font-size: 25px;
}

.intro-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/***************************************ABOUT***************************************/


